import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from "@angular/router";
import { AppRoutingModule } from './app-routing.module';
import { routes } from "./app-routing.module";
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { TopbarComponent } from './topbar/topbar.component';
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { ServicesComponent } from './services/services.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { IndustriesComponent } from './industries/industries.component';
import { HighlightsComponent } from './highlights/highlights.component';
import { ProductsComponent } from './products/products.component';
import { AiTechnologyComponent } from './ai-technology/ai-technology.component';
import { BlockchainComponent } from './blockchain/blockchain.component';
import { MobiledevComponent } from './mobiledev/mobiledev.component';
import { aboutUsService } from './aboutus/aboutus.service';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent,
    TopbarComponent,
    HeaderComponent,

    FooterComponent,
    HomeComponent,
    ServicesComponent,
    AboutusComponent,
    IndustriesComponent,
    HighlightsComponent,
    ProductsComponent,
    AiTechnologyComponent,
    BlockchainComponent,
    MobiledevComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    NgbModule,
    NgImageSliderModule,


    ReactiveFormsModule,
    RouterModule.forRoot(routes)
  ],
  providers: [aboutUsService, ErrorHandler,
     { provide: LocationStrategy, useClass: PathLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule { }
