<div class="head-banner">
  <img src="./assets/img/page-ban-products.png" />
</div>
<main id="main" class="py-4">
  <!-- ======= Featured Section ======= -->
  <section id="featured" class="featured">
    <div class="container">
      <div class="section-title pb-2">
        <h2>Products</h2>
      </div>
      <!-- <p>
        Enterprise needs composable AI framework to manage different AI services
        like ensemble and federated learning with MLOps and DataOps along with
        governance, privacy and lineage.
      </p> -->
      <div class="row">
        <div class="col-lg-4">
          <div class="card" data-aos="fade-up">
            <div class="img-hover-zoom">
              <img class="card-img-top" src="./assets/img/lms.png" />
            </div>

            <div class="card-body">
              <h5 class="card-title">LMS</h5>
              <p class="card-text">
                Subscription-based LMS instance with out-of-the-box features,
                setup in no-time.
              </p>
              <a
                href="https://demo.mazelonlms.com/?redirect=0"
                target="_blank"
                class="btn btn-primary"
                ><i class="bi bi-globe"></i> Visit Website</a
              >
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="card" data-aos="fade-up">
            <div class="img-hover-zoom">
              <img class="card-img-top" src="./assets/img/bocxy.png" />
            </div>

            <div class="card-body">
              <h5 class="card-title">Bocxy</h5>
              <p class="card-text">
                World's 1st unified service and product platform for Salon SPA
              </p>
              <a
                href="https://bocxy.com/"
                target="_blank"
                class="btn btn-primary"
                ><i class="bi bi-globe"></i> Visit Website</a
              >
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card" data-aos="fade-up">
            <div class="img-hover-zoom">
              <img class="card-img-top" src="./assets/img/kedayam.png" />
            </div>

            <div class="card-body">
              <h5 class="card-title">Kalvi Kedayam</h5>
              <p class="card-text">
                AI Driven, Analytical Products, Broadcasting Student from
                Schools.
              </p>
              <a href="#" class="btn btn-primary"
                ><i class="bi bi-globe"></i> Visit Website</a
              >
            </div>
          </div>
        </div>
      </div>

      <div class="row py-4">
        <div class="col-lg-4">
          <div class="card" data-aos="fade-up">
            <div class="img-hover-zoom">
              <img class="card-img-top" src="./assets/img/dna.png" />
            </div>

            <div class="card-body">
              <h5 class="card-title">DNA</h5>
              <p class="card-text">
                Elevate your career, your life and your momentum with resources
                and benefits from Dent National Academy.
              </p>
              <!-- <a
                href="#"
                target="_blank"
                class="btn btn-primary"
                ><i class="bi bi-globe"></i> Visit Website</a
              > -->
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="card" data-aos="fade-up">
            <div class="img-hover-zoom">
              <img class="card-img-top" src="./assets/img/icemeter.png" />
            </div>

            <div class="card-body">
              <h5 class="card-title">Icemeter</h5>
              <p class="card-text">
                IceMeter, India’s Largest Taxi Booking App, is the fastest and
                the most affordable way for daily commute.
              </p>
              <!-- <a href="#" class="btn btn-primary"
                ><i class="bi bi-globe"></i> Visit Website</a
              > -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Featured Section -->
</main>
