import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ai-technology',
  templateUrl: './ai-technology.component.html',
  styleUrls: ['./ai-technology.component.css']
})
export class AiTechnologyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
