<div id="header-sticky">
  <nav class="container navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="#">
      <img src="./assets/img/mazelon-logo.png" />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/home"
            >Home <span class="sr-only">(current)</span></a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/industries"
            >Industries</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/services"
            >Services</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            routerLinkActive="active"
            routerLink="/highlights"
          >
            Highlights</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/products"
            >Products</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/contactus"
            >Contact</a
          >
        </li>
        <!-- <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Products
          </a>

          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" routerLink="/comingsoon">LMS</a>
          </div>
        </li> -->
      </ul>
    </div>
  </nav>
</div>
