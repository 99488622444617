<div class="head-banner">
  <img src="./assets/img/page-ban-highlights.png" />
</div>
<main id="main" class="py-4">
  <!-- ======= Featured Section ======= -->
  <section id="featured" class="featured">
    <div class="container">
      <div class="section-title pb-2">
        <h2>Highlights</h2>
      </div>
      <!-- <p>
        Enterprise needs composable AI framework to manage different AI services
        like ensemble and federated learning with MLOps and DataOps along with
        governance, privacy and lineage.
      </p> -->
      <div class="row">
        <div class="col-lg-4">
          <div class="card" data-aos="fade-up">
            <div class="img-hover-zoom">
              <img class="card-img-top" src="./assets/img/telefy.png" />
            </div>

            <div class="card-body">
              <h5 class="card-title">Telefy</h5>
              <p class="card-text">
                Worlds first credit score oriented Multichain DEFI solution.
                Explore Tele score oriented multi chain DEFI innovations crafted
                by our TeleFyers
              </p>
              <a
                href="http://telefy.finance/"
                target="_blank"
                class="btn btn-primary"
                ><i class="bi bi-globe"></i> Visit Website</a
              >
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="card" data-aos="fade-up">
            <div class="img-hover-zoom">
              <img class="card-img-top" src="./assets/img/rungila.png" />
            </div>

            <div class="card-body">
              <h5 class="card-title">Rungila</h5>
              <p class="card-text">
                Neuroadaptive Assessment Platform to retrospect your skills,
                Talents and also provide counselling as needed
              </p>
              <a
                href="https://rungila.com/en/"
                target="_blank"
                class="btn btn-primary"
                ><i class="bi bi-globe"></i> Visit Website</a
              >
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="card" data-aos="fade-up">
            <div class="img-hover-zoom">
              <img class="card-img-top" src="./assets/img/dropouts.png" />
            </div>

            <div class="card-body">
              <h5 class="card-title">Stop Dropouts</h5>
              <p class="card-text">
                AI Driven, Analytical Products, Broadcasting Student Dropouts
                from Schools.
              </p>
              <a
                href="https://stopdropouts.com/en/"
                target="_blank"
                class="btn btn-primary"
                ><i class="bi bi-globe"></i> Visit Website</a
              >
            </div>
          </div>
        </div>
      </div>

      <div class="row py-4">
        <div class="col-lg-4">
          <div class="card" data-aos="fade-up">
            <div class="img-hover-zoom">
              <img class="card-img-top" src="./assets/img/bocxy.png" />
            </div>

            <div class="card-body">
              <h5 class="card-title">Bocxy</h5>
              <p class="card-text">
                World's 1st unified service and product platform for Salon SPA
              </p>
              <a
                href="https://bocxy.com/"
                target="_blank"
                class="btn btn-primary"
                ><i class="bi bi-globe"></i> Visit Website</a
              >
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="card" data-aos="fade-up">
            <div class="img-hover-zoom">
              <img class="card-img-top" src="./assets/img/nutriox.png" />
            </div>

            <div class="card-body">
              <h5 class="card-title">Nutriox</h5>
              <p class="card-text">
                Nutriox Distribution is your go-to store for all your Health
                supplement needs.
              </p>
              <a
                href="http://nutrioxdistribution.com/"
                target="_blank"
                class="btn btn-primary"
                ><i class="bi bi-globe"></i> Visit Website</a
              >
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="card" data-aos="fade-up">
            <div class="img-hover-zoom">
              <img class="card-img-top" src="./assets/img/hestia.png" />
            </div>

            <div class="card-body">
              <h5 class="card-title">Hestia</h5>
              <p class="card-text">
                Today's women are strong and independent. Helping to Choose the
                Best accessories to make them Look and Feeling Great.
              </p>
              <a
                href="https://hestia-shop.com/"
                target="_blank"
                class="btn btn-primary"
                ><i class="bi bi-globe"></i> Visit Website</a
              >
            </div>
          </div>
        </div>
      </div>

      <div class="row py-4">
        <div class="col-lg-4">
          <div class="card" data-aos="fade-up">
            <div class="img-hover-zoom">
              <img class="card-img-top" src="./assets/img/weaponry.png" />
            </div>

            <div class="card-body">
              <h5 class="card-title">Weaponry</h5>
              <p class="card-text">
                Weaponry bringing you the apparels that are comfortable for
                workouts along with a feel-good factor.
              </p>
              <a
                href="https://weaponryapparel.shop/"
                target="_blank"
                class="btn btn-primary"
                ><i class="bi bi-globe"></i> Visit Website</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Featured Section -->
</main>
