<div class="head-banner">
  <img src="./assets/img/page-ban-cont.jpg" />
</div>
<main id="main" class="py-4">
  <!-- ======= About Section ======= -->
  <section id="contactus" class="contactus">
    <!-- Wrapper container -->
    <div class="container">
      <!-- <div class="section-title">
        <h2>Contact Us</h2>
      </div> -->
      <div class="row">
        <div class="col-md-6">
          <div class="cont-info" data-aos="fade-right">
            <div class="info">
              <div>
                <i class="bi bi-geo-alt-fill"></i>
                <p>
                  123 C Bharath Street , <br />
                  Medavakkam , Chennai - 600100.
                </p>
              </div>

              <div>
                <i class="bi bi-envelope-fill"></i>
                <p>contact@mazelon.com</p>
              </div>

              <div>
                <i class="bi bi-telephone-fill"></i>
                <p>+91 9840152173</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="card" data-aos="fade-up">
            <div class="card-body">
              <div class="section-title">
                <h2>Contact Us</h2>
              </div>
              <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                  <label>Full Name</label>
                  <input
                    type="text"
                    formControlName="fullname"
                    class="form-control"
                    [ngClass]="{
                      'is-invalid': submitted && f.fullname.errors
                    }"
                  />
                  <div
                    *ngIf="submitted && f.fullname.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.fullname.errors.required">
                      Please enter your name
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label>Email</label>
                  <input
                    type="text"
                    formControlName="email"
                    class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                  />
                  <div
                    *ngIf="submitted && f.email.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.email.errors.required">
                      Please enter your email address
                    </div>
                    <div *ngIf="f.email.errors.email">
                      Email must be a valid email address
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label>Message</label>
                  <textarea
                    type="text"
                    formControlName="message"
                    class="form-control"
                    [ngClass]="{
                      'is-invalid': submitted && f.message.errors
                    }"
                  ></textarea>
                  <div
                    *ngIf="submitted && f.message.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.message.errors.required">
                      Please write your messsage
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <button class="btn btn-primary">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
