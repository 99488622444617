import { HttpClient ,HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ErrorHandler } from './error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class aboutUsService {
  constructor(private httpClient: HttpClient, private eh: ErrorHandler) {

  }

  sentEmail(cp: any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');

 
    return this.httpClient.post(
      'https://mazelon.com/forms/contact.php', 
      cp, 
      { headers, responseType: 'text'}
    ).pipe(catchError(this.eh.handleError));
  }


  // https://mazelon.com/forms/contact.php
}