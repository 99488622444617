<!-- ======= Footer ======= -->
<footer id="footer">
  <!-- <div class="footer-newsletter">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <h4>Our Newsletter</h4>
          <p>
            Tamen quem nulla quae legam multos aute sint culpa legam noster
            magna
          </p>
        </div>
        <div class="col-lg-6">
          <form action="" method="post">
            <input type="email" name="email" /><input
              type="submit"
              value="Subscribe"
            />
          </form>
        </div>
      </div>
    </div>
  </div> -->

  <div class="footer-top">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-6 footer-links">
          <h4>Useful Links</h4>
          <ul>
            <li>
              <i class="bx bx-chevron-right"></i> <a routerLink="/">Home</a>
            </li>
            <li>
              <i class="bx bx-chevron-right"></i>
              <a routerLink="/contactus">Contact Us</a>
            </li>
            <li>
              <i class="bx bx-chevron-right"></i>
              <a routerLink="/industries">Industries</a>
            </li>
            <li>
              <i class="bx bx-chevron-right"></i>
              <a routerLink="/services">Services</a>
            </li>
            <li>
              <i class="bx bx-chevron-right"></i>
              <a href="#">Terms of service</a>
            </li>
            <li>
              <i class="bx bx-chevron-right"></i> <a href="#">Privacy policy</a>
            </li>
          </ul>
        </div>

        <div class="col-lg-3 col-md-6 footer-links">
          <h4>Our Services</h4>
          <ul>
            <li>
              <i class="bx bx-chevron-right"></i>
              <a routerLink="/services">Product Engineering</a>
            </li>
            <li>
              <i class="bx bx-chevron-right"></i>
              <a routerLink="/services">Digital Transformation</a>
            </li>
            <li>
              <i class="bx bx-chevron-right"></i>
              <a routerLink="/services">Enterprise Solutions</a>
            </li>
            <li>
              <i class="bx bx-chevron-right"></i>
              <a routerLink="/services">Technology Consulting</a>
            </li>
            <li>
              <i class="bx bx-chevron-right"></i>
              <a routerLink="/services">Business Consulting</a>
            </li>
          </ul>
        </div>

        <div class="col-lg-3 col-md-6 footer-contact">
          <h4>Contact Us</h4>
          <p>
            123 C Bharath Street , <br />
            Medavakkam , Chennai - 600100.
            <br /><br />
            <strong>Phone:</strong> +91 9962346734

            <br />
            <strong>Email:</strong> contact@mazelon.com<br />
          </p>
        </div>

        <div class="col-lg-3 col-md-6 footer-info">
          <h3>About Mazelon</h3>
          <p>
            Mazelon is a high-quality independent right-sourcing business and
            technology consultant. We assist clients to assess and accomplish
            the risks associated with global development programs and impeccably
            integrate the processes to ensure business steadiness.
          </p>
          <div class="social-links mt-3">
            <a
              href="https://twitter.com/mazelontech"
              target="_blank"
              class="twitter"
              ><i class="bx bxl-twitter"></i
            ></a>
            <a
              href="https://www.facebook.com/people/Mazelon-Chennai/100009322075572/"
              target="_blank"
              class="facebook"
              ><i class="bx bxl-facebook"></i
            ></a>
            <a
              href="https://www.instagram.com/Mazelontech/"
              target="_blank"
              class="instagram"
              ><i class="bx bxl-instagram"></i
            ></a>
            <a href="#" target="_blank" class="linkedin"
              ><i class="bx bxl-linkedin"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="copyright">
      &copy; 2021 <strong><span>Mazelon Technologies Pvt Ltd</span></strong
      >. All Rights Reserved
    </div>
    <!-- <div class="credits">
    <a href="#">Mazelon</a>
    </div> -->
  </div>
</footer>
<button class="btn back-to-tops" *ngIf="isShow" (click)="gotoTop()">
  <i class="bi bi-arrow-up-short"></i>
</button>

<!-- End Footer -->
