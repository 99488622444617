<div class="head-banner">
  <img src="./assets/img/page-ban1.jpg" />
</div>
<main id="main" class="py-4">
  <!-- ======= About Section ======= -->
  <section id="industries" class="industries">
    <div class="container">
      <div class="section-title">
        <h2>Industries</h2>
      </div>
      <div class="row">
        <div class="col-lg-6 img-block" data-aos="fade-right">
          <div class="img-hover-zoom">
            <img src="./assets/img/retail-img.jpg" class="img-fluid" alt="" />
          </div>
        </div>
        <div class="col-lg-6 pt-4 content" data-aos="fade-left">
          <h3><i class="fas fa-briefcase secondary-color"></i> Retail</h3>
          <p class="fst-italic">
            Providing a personalized shopping skill is profligate becoming
            adomineering, calling for a 360-degree clientvision with seamless
            incorporation across all interaction channels. This involves a
            linked supply chain to permit omni-channel processes, answer to
            demand-supply variations in real-time and make improved pricing
            choices.
          </p>
          <p>
            Mazelon carries cloud and mobile ERP solutions to Marketing,
            Restaurant and Distribution trades. We aid businesses develop with
            nominal staff, least skills along with 100% exact and dependable
            solutions. Mazleon’s products and solutions authorizes businesses to
            pleasure its customers, accomplish them proficiently, connect and
            collaborate with its stakeholders and most significantly take timely
            results on the move. We address the needs of anextensive spectrum of
            clients from small self-determining stores to local chains and
            hugeinitiatives.
          </p>
          <p>
            At Mazelon we provide a combined platform to streamline all
            back-office practices, like accounting, CRM, and ecommerce. With
            built-in business intelligence, reporting, and real-time
            perceptibility across your organization.
          </p>
          <p>
            We empower businesses of all scopes and industries to unleash
            progress, reduce IT expenses, and exclude operational
            inefficiencies-- allowing you to focus on key, income-generating
            choices, rather than concern about your back-end methods.
          </p>
          <!-- <ul>
            <li>
              <i class="bi bi-check-circle"></i> Ullamco laboris nisi ut aliquip
              ex ea commodo consequat.
            </li>
            <li>
              <i class="bi bi-check-circle"></i> Duis aute irure dolor in
              reprehenderit in voluptate velit.
            </li>
            <li>
              <i class="bi bi-check-circle"></i> Ullamco laboris nisi ut aliquip
              ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
              voluptate trideta storacalaperda mastiro dolore eu fugiat nulla
              pariatur.
            </li>
          </ul>
          <p>
            Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
            irure dolor in reprehenderit in voluptate velit esse cillum dolore
            eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
            proident, sunt in culpa qui officia deserunt mollit anim id est
            laborum
          </p> -->
        </div>
      </div>

      <div class="row py-4">
        <div class="col-lg-6 pt-4 content" data-aos="fade-left">
          <h3><i class="fas fa-briefcase secondary-color"></i> Education</h3>
          <p class="fst-italic">
            With the digital technology overwhelming our education system, every
            institution whether huge or lesser, online or public/private, etc.
            are assured to grip this change, as this is the need of the hour. As
            learning and technology go hand in hand, the beginners are taking to
            the digital medium such as social, mobile and Cloud to achieve
            academic requirements.
          </p>
          <p>
            We, at Mazelon Education Practice, act as an enabler to aid
            educational institutions classify and deal with the varying
            environment. We bid a comprehensive portfolio of education
            technology solutions for these institutions after examiningchances,
            challenges, stakeholder expectations and the knowledge required to
            deal with it. challenges, stakeholder expectations and the knowledge
            required to deal with it.
          </p>
          <p>
            Our solutions variety from Business Revolution Strategy and
            Enterprise Architecture Accessing Services to Application Design,
            Growth, Operation, Maintenance and Support; to IT Infrastructure and
            Business Process Outsourcing.
          </p>
        </div>
        <div class="col-lg-6 img-block" data-aos="fade-right">
          <div class="img-hover-zoom">
            <img src="./assets/img/edu-img.jpg" class="img-fluid" alt="" />
          </div>
        </div>
      </div>

      <div class="row py-4">
        <div class="col-lg-6 img-block" data-aos="fade-right">
          <div class="img-hover-zoom">
            <img src="./assets/img/logi-img.jpg" class="img-fluid" alt="" />
          </div>
        </div>
        <div class="col-lg-6 pt-4 content" data-aos="fade-left">
          <h3><i class="fas fa-briefcase secondary-color"></i> Logistics</h3>
          <p class="fst-italic">
            With the beginning of the digital era, logistics corporations are
            obligated to redefine and accomplish their business worldwide.
            Providing up-to-date business solutions along with modern trends and
            skills to keep their clients content has become a mandate. The
            Logistics industry is at the essential of the global supply chain
            and is continuallydeveloping to keep pace with the
            cumulativeclientclaim for cost-effective solutions to
            improvethroughput, quality, optimization and timing, which have
            become serious aspects of their businesses.
          </p>
          <p>
            Mazelon-depth sphere expertise and experience to support logistics
            companies by offering facilities and solutions for enabling
            real-time “Information flow” throughout the journey, enabling
            business owners to take well-versed and faster decisions, thus,
            resulting in optimized operation for improved margins and enhanced
            clientpractices. To enable and improve the above end-to-end journey
            of supply chain and logistics, we aid to bring digital
            transformation and enhance customer experience through various
            developing technologies.
          </p>
          <p>
            Data Integration solutions to upload data grounded on business rules
            and Testing Accelerators with in-built business scenarios are some
            of the inventive solutions being used by our key logistics clients
            to achieve operational productivity and effectiveness.
          </p>
          <p>
            We have proven expertise in Customer Skill Transformation,
            Application Transformation Management, Legacy Upgrading, Product
            Engineering, Digital Assurance (Independent Testing Services),
            Infrastructure Management Services and Business Process Services to
            meet our client needs.
          </p>
        </div>
      </div>
    </div>
  </section>
  <!-- End About Section -->
</main>
