<div class="head-banner">
  <img src="./assets/img/page-ban-blockchain.png" />
</div>
<main id="main" class="py-4">
  <!-- ======= Featured Section ======= -->
  <section id="featured" class="featured">
    <div class="container">
      <div class="section-title pb-2">
        <h2>Our Blockchain Services</h2>
      </div>
      <p>
        We understand that enterprises of different sizes conduct business
        differently. We transform their relationship with their suppliers,
        vendors and partners with digital solutions.
      </p>
      <div class="row">
        <div class="col-lg-4">
          <div class="icon-box" data-aos="fade-up">
            <img src="./assets/img/blockchian-ico.png" />
            <h3><a>Blockchain Technology</a></h3>
            <p>
              We design distributed ledger technology for both public and
              private blockchains, including custom tokens, individual nodes,
              hash algorithms, and architectures. Our consensus protocols
              provide templates for proof of work and definition, reducing
              double-spending mistakes and the need for obsolete third parties.
            </p>
          </div>
        </div>
        <div class="col-lg-4 mt-4 mt-lg-0">
          <div class="icon-box" data-aos="fade-up">
            <img src="./assets/img/smart-cont.png" />
            <h3><a>Smart Contracts</a></h3>
            <p>
              We program smart contracts for blockchain networks, providing
              decentralized network solutions for the e-commerce, banking, real
              estate, supply chain, and gaming industries. We design smart
              contracts to register loan requests and intellectual property,
              validate insurance eligibility, produce credit letters for foreign
              transactions, and establish decentralized autonomous organizations
              (DAOs).
            </p>
          </div>
        </div>
        <div class="col-lg-4 mt-4 mt-lg-0">
          <div class="icon-box" data-aos="fade-up">
            <img src="./assets/img/wallet-x.png" />
            <h3><a>Wallets Exchange Applications</a></h3>
            <p>
              Our services for managed blockchain applications include
              blockchain wallet applications and exchange platforms for
              desktops, mobile devices, and browser applications. We code apps
              for exchanging multiple cryptocurrencies and blockchain based
              tokens, auto-generating public and private keys, making
              transactions online, engaging in Initial Coin Offerings (ICO), and
              analyzing any block's hypermedia and data background.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Featured Section -->
</main>
