<!-- ======= Top Bar ======= -->
<section id="topbar" class="d-flex align-items-center">
  <div
    class="container d-flex justify-content-center justify-content-md-between"
  >
    <div class="contact-info d-flex align-items-center">
      <i class="bi bi-envelope d-flex align-items-center"
        ><a href="mailto:contact@example.com">contact@mazelon.com</a></i
      >
      <i class="bi bi-phone d-flex align-items-center ms-4"
        ><span>+91 9840152173</span></i
      >
    </div>
    <div class="social-links d-none d-md-flex align-items-center">
      <a href="https://twitter.com/mazelontech" target="_blank" class="twitter"
        ><i class="bi bi-twitter"></i
      ></a>
      <a
        href="https://www.facebook.com/people/Mazelon-Chennai/100009322075572/"
        target="_blank"
        class="facebook"
        ><i class="bi bi-facebook"></i
      ></a>
      <a
        href="https://www.instagram.com/Mazelontech/"
        target="_blank"
        class="instagram"
        ><i class="bi bi-instagram"></i
      ></a>
      <a href="#" target="_blank" class="linkedin"
        ><i class="bi bi-linkedin"></i
      ></a>
    </div>
  </div>
</section>
