<!-- ======= Hero Section ======= -->
<section id="hero">
  <div class="hero-container">
    <div
      id="carouselExampleIndicators"
      data-bs-interval="5000"
      class="carousel slide carousel-fade"
      data-bs-ride="carousel"
    >
      <div class="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="0"
          class="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
      </div>
      <!-- <ol class="carousel-indicators">
        <li
          type="button"
          data-target="#carouselExampleIndicators"
          data-bs-slide-to="0"
          class="active"
          aria-current="true"
          aria-label="Slide 1"
          class="active"
        ></li>
        <li
          type="button"
          data-target="#carouselExampleIndicators"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></li>
        <li
          type="button"
          data-target="#carouselExampleIndicators"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></li>
      </ol> -->
      <div class="carousel-inner">
        <div
          class="carousel-item active"
          style="background: url(assets/img/slide/ai-banner-1.png)"
        >
          <div class="carousel-container">
            <div class="carousel-content">
              <h2 class="animate__animated animate__fadeInDown">
                Artificial <span>Inteligence</span>
              </h2>
              <p class="animate__animated animate__fadeInUp">
                Enterprise needs composable AI framework to manage different AI
                services like ensemble and federated learning with MLOps and
                DataOps.
              </p>
              <!-- <a class="btn-get-started animate__animated animate__fadeInUp"
                >Get Started</a
              > -->
            </div>
          </div>
        </div>

        <div
          class="carousel-item"
          style="background: url(assets/img/slide/bc-banner.png)"
        >
          <div class="carousel-container">
            <div class="carousel-content">
              <h2 class="animate__animated fanimate__fadeInDown">
                Blockchain<span> Technology</span>
              </h2>
              <p class="animate__animated animate__fadeInUp">
                We design distributed ledger technology for both public and
                private blockchains, including custom tokens, individual nodes,
                hash algorithms, and architectures.
              </p>
              <!-- <a class="btn-get-started animate__animated animate__fadeInUp"
                >Get Started</a
              > -->
            </div>
          </div>
        </div>

        <div
          class="carousel-item"
          style="background: url(assets/img/slide/mob-dev-ban.jpg)"
        >
          <div class="carousel-container">
            <div class="carousel-content">
              <h2 class="animate__animated fanimate__fadeInDown">
                Mobile<span> Apps</span>
              </h2>
              <p class="animate__animated animate__fadeInUp">
                Full-fledged mobile application development that can meet the
                requirement of the business.
              </p>
              <!-- <a class="btn-get-started animate__animated animate__fadeInUp"
                >Get Started</a
              > -->
            </div>
          </div>
        </div>
      </div>
      <a
        class="carousel-control-prev"
        href="#carouselExampleIndicators"
        role="button"
        data-slide="prev"
      >
        <span
          class="carousel-control-prev-icon bi bi-chevron-left"
          aria-hidden="true"
        ></span>
        <span class="sr-only">Previous</span>
      </a>
      <a
        class="carousel-control-next"
        href="#carouselExampleIndicators"
        role="button"
        data-slide="next"
      >
        <span
          class="carousel-control-next-icon bi bi-chevron-right"
          aria-hidden="true"
        ></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
</section>
<!-- End Hero -->

<main id="main">
  <!-- ======= Featured Section ======= -->
  <section id="featured" class="featured home-featured">
    <div class="container">
      <div class="row">
        <div class="col-lg-4">
          <div class="icon-box" data-aos="fade-up" routerLink="/aitech">
            <img src="./assets/img/ai.png" />
            <h3><a>Artificial Intelligence</a></h3>
            <p>
              Artificial intelligence is intelligence demonstrated by machines,
              as opposed to natural intelligence displayed by animals including
              humans.
            </p>
          </div>
        </div>
        <div class="col-lg-4 mt-4 mt-lg-0">
          <div class="icon-box" data-aos="fade-up" routerLink="/blockchain">
            <img src="./assets/img/blockchain.png" />
            <h3><a>Blockchain</a></h3>
            <p>
              Blockchain wallet is intuitive and easy to use. The Company does
              store your wallet on their servers which does involve third party
              risk.
            </p>
          </div>
        </div>
        <div class="col-lg-4 mt-4 mt-lg-0">
          <div class="icon-box" data-aos="fade-up" routerLink="/mobiledev">
            <img src="./assets/img/mobile-app.png" />
            <h3><a>Mobile Apps</a></h3>
            <p>
              Mobile development services that excel the expectations of the
              brands by giving beautiful designs, smooth navigations, and latest
              integrated features, and fewer maintenance requirements.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Featured Section -->

  <!-- ======= About Section ======= -->
  <section id="about" class="about">
    <div class="container">
      <div class="section-title">
        <h2>Who We Are</h2>
      </div>
      <div class="row">
        <div class="col-lg-4 flex-wrapper" data-aos="fade-right">
          <div class="img-hover-zoom">
            <img
              src="./assets/img/whowe1.jpg"
              class="img-fluid img-responsive"
              alt=""
            />
          </div>
        </div>
        <div class="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
          <!-- <h3>Who We Are</h3> -->
          <div class="who-we-are">
            <p>
              Mazelon is a high-quality independent right-sourcing business and
              technology consultant. We assist clients to assess and accomplish
              the risks associated with global development programs and
              impeccably integrate the processes to ensure business steadiness.
              One of the foremost challenges in establishing a captive center is
              finding the right people quickly and resourcefully, design
              competitive compensation benefits packages, create India specific
              HR Policy, etc.
            </p>

            <p>
              We consider ourselves as partners of development. We will act as
              an extended arm of your group, maintaining top-notch
              confidentiality, openness, and integrity. Our team is obsessedwith
              the strong need to leave anoptimistic and lasting impression. Our
              in-depth know-how as well as functional and technical knowledge
              help us in this. Many Companies with us to date, have been
              convinced of our services in the context of the implementation of
              strategic business solutions.
            </p>
          </div>
        </div>
        <!-- <div class="col-lg-4" data-aos="fade-right">
          <div class="img-hover-zoom">
            <img src="./assets/img/whowe2.jpg" class="img-fluid" alt="" />
          </div>
        </div> -->
      </div>
    </div>
  </section>
  <!-- End About Section -->

  <!-- ======= Services Section ======= -->
  <section id="services" class="services">
    <div class="container">
      <div class="section-title">
        <h2>Services</h2>
      </div>

      <div class="row">
        <div
          class="col-lg-3 col-md-6 d-flex align-items-stretch"
          data-aos="fade-up"
        >
          <div class="icon-box">
            <div class="icon"><i class="bx bxs-wrench"></i></div>
            <h4><a>Product Engineering</a></h4>
            <p>
              We understand the present needs, working on the innovative ideas,
              designing and developing the software, then testing and bringing
              it to use.
            </p>
          </div>
        </div>

        <div
          class="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
          data-aos="fade-up"
        >
          <div class="icon-box">
            <div class="icon"><i class="bx bxs-bulb"></i></div>
            <h4><a>Enterprise Solutions</a></h4>
            <p>
              Integrating various aspects of a company's business through the
              interchange of data and information from different business
              process areas and related databases.
            </p>
          </div>
        </div>

        <div
          class="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
          data-aos="fade-up"
        >
          <div class="icon-box">
            <div class="icon"><i class="bx bxs-search"></i></div>
            <h4><a>Technology Consulting</a></h4>
            <p>
              We progress long-term technology strategy that will enable every
              organization to be agile, flexible, customer responsive.
            </p>
          </div>
        </div>

        <div
          class="col-lg-3 col-md-6 d-flex align-items-stretch"
          data-aos="fade-up"
        >
          <div class="icon-box">
            <div class="icon"><i class="bx bxl-digitalocean"></i></div>
            <h4><a>Digital Transformation</a></h4>
            <p>
              We convert all the business strategies, activities, models in an
              organization to a more digitalized way.
            </p>
          </div>
        </div>

        <!-- <div
          class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
          data-aos="fade-up"
        >
          <div class="icon-box">
            <div class="icon"><i class="bx bx-slideshow"></i></div>
            <h4><a href="">Dele cardo</a></h4>
            <p>
              Quis consequatur saepe eligendi voluptatem consequatur dolor
              consequuntur
            </p>
          </div>
        </div>

        <div
          class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
          data-aos="fade-up"
        >
          <div class="icon-box">
            <div class="icon"><i class="bx bx-arch"></i></div>
            <h4><a href="">Divera don</a></h4>
            <p>
              Modi nostrum vel laborum. Porro fugit error sit minus sapiente sit
              aspernatur
            </p>
          </div>
        </div> -->
      </div>
    </div>
  </section>
  <!-- End Services Section -->

  <!-- ======= Services Section ======= -->
  <section id="services" class="services">
    <div class="container">
      <div class="section-title">
        <h2>Technologies</h2>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="tech-img-container">
            <img src="./assets/img/technology-1.png" />
          </div>
        </div>
      </div>
      <!-- <div class="row">
        <div
          class="col-lg-4 col-md-6 d-flex align-items-stretch"
          data-aos="fade-up"
        >
          <div class="icon-box">
            <div class="icon"><i class="bx bxl-dribbble"></i></div>
            <h4><a href="">Lorem Ipsum</a></h4>
            <p>
              Voluptatum deleniti atque corrupti quos dolores et quas molestias
              excepturi
            </p>
          </div>
        </div>

        <div
          class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
          data-aos="fade-up"
        >
          <div class="icon-box">
            <div class="icon"><i class="bx bx-file"></i></div>
            <h4><a href="">Sed ut perspiciatis</a></h4>
            <p>
              Duis aute irure dolor in reprehenderit in voluptate velit esse
              cillum dolore
            </p>
          </div>
        </div>

        <div
          class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
          data-aos="fade-up"
        >
          <div class="icon-box">
            <div class="icon"><i class="bx bx-tachometer"></i></div>
            <h4><a href="">Magni Dolores</a></h4>
            <p>
              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
              officia
            </p>
          </div>
        </div>

        <div
          class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
          data-aos="fade-up"
        >
          <div class="icon-box">
            <div class="icon"><i class="bx bx-world"></i></div>
            <h4><a href="">Nemo Enim</a></h4>
            <p>
              At vero eos et accusamus et iusto odio dignissimos ducimus qui
              blanditiis
            </p>
          </div>
        </div>

        <div
          class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
          data-aos="fade-up"
        >
          <div class="icon-box">
            <div class="icon"><i class="bx bx-slideshow"></i></div>
            <h4><a href="">Dele cardo</a></h4>
            <p>
              Quis consequatur saepe eligendi voluptatem consequatur dolor
              consequuntur
            </p>
          </div>
        </div>

        <div
          class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
          data-aos="fade-up"
        >
          <div class="icon-box">
            <div class="icon"><i class="bx bx-arch"></i></div>
            <h4><a href="">Divera don</a></h4>
            <p>
              Modi nostrum vel laborum. Porro fugit error sit minus sapiente sit
              aspernatur
            </p>
          </div>
        </div>
      </div> -->
    </div>
  </section>
  <!-- End Services Section -->

  <!-- ======= Clients Section ======= -->
  <section id="clients" class="clients">
    <div class="container">
      <div class="section-title">
        <h2>Clients</h2>
        <!-- <p>
          Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex
          aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos
          quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia
          fugiat sit in iste officiis commodi quidem hic quas.
        </p> -->
      </div>

      <div style="text-align: center; margin: 0; padding: 0">
        <ng-image-slider
          #nav
          [images]="imageObject"
          [infinite]="true"
          [autoSlide]="2"
          [imageSize]="{ width: '200', height: 80 }"
          slideImage="1"
        ></ng-image-slider>
      </div>
    </div>
  </section>
  <!-- End Clients Section -->
</main>
<!-- End #main -->
