import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutusComponent } from './aboutus/aboutus.component';
import { AiTechnologyComponent } from './ai-technology/ai-technology.component';
import { BlockchainComponent } from './blockchain/blockchain.component';
import { HighlightsComponent } from './highlights/highlights.component';
import { HomeComponent } from './home/home.component';
import { IndustriesComponent } from './industries/industries.component';
import { MobiledevComponent } from './mobiledev/mobiledev.component';
import { ProductsComponent } from './products/products.component';
import { ServicesComponent } from './services/services.component';

export const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'industries', component: IndustriesComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'highlights', component: HighlightsComponent },
  { path: 'products', component: ProductsComponent },
  { path: 'aitech', component: AiTechnologyComponent },
  { path: 'blockchain', component: BlockchainComponent },
  { path: 'mobiledev', component: MobiledevComponent },
  { path: 'contactus', component: AboutusComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }