<div class="head-banner">
  <img src="./assets/img/page-ban2.jpg" />
</div>
<main id="main" class="py-4">
  <!-- ======= Services Section ======= -->
  <section id="services" class="services">
    <div class="container">
      <div class="section-title">
        <h2>Services</h2>
      </div>
      <div class="row">
        <div class="col-lg-6" data-aos="fade-right">
          <div class="img-border1"></div>
          <div class="img-hover-zoom">
            <img src="./assets/img/service-1.jpg" class="img-fluid" alt="" />
          </div>
        </div>
        <div class="col-lg-6 pt-4 content" data-aos="fade-left">
          <h3>
            <i class="fas fa-tools secondary-color"></i> Product Engineering
          </h3>
          <p class="fst-italic">
            We understandthe present needs, working on the innovative ideas,
            designing and developing the software, then testing and bringing it
            to use. It involves the whole life cycle of the product from the
            idea of innovation time to the user accepting phase.
          </p>
          <p>
            We make use of the information and analyze the data, get various
            alternatives possible, evaluate them, and come to the decision of
            the best alternative possible. All these steps help to attract the
            target customer and also for the larger reach of the product.
          </p>
          <p>
            In addition to primary technical strength across diverse
            platforms,Mazelon brings in valuable insurance field expertise to
            projects providing a consultative methodology to projects. We convey
            end-to-end progress and testing facilities for policy
            administration, claims management, underwriting and billing with our
            industry standard frameworks and strong practices.
          </p>
          <p>
            With a collection of product partnerships in insurance field,Mazelon
            has been able to effectively influence product and practice
            excellence to provide best in class skill to customers.
          </p>
        </div>
      </div>

      <div class="row py-4">
        <div class="col-lg-6 pt-4 content" data-aos="fade-left">
          <h3>
            <i class="fas fa-tools secondary-color"></i> Digital Transformation
          </h3>
          <p class="fst-italic">
            We convert all the business strategies, activities, models in an
            organization to a more digitalized way. With the changes in the
            existing business process, it helps to meet the present customer
            demand, provide them with better customer satisfaction.
          </p>
          <p>
            Process starts with gaining new experience and designing innovative
            business models, then develop digital DNA, adopt new technologies to
            existing infrastructure, take decisions based on the data, co-create
            and co-innovate with new partners.
          </p>
        </div>
        <div class="col-lg-6" data-aos="fade-right">
          <div class="img-border2"></div>
          <div class="img-hover-zoom">
            <img src="./assets/img/service-2.jpg" class="img-fluid" alt="" />
          </div>
        </div>
      </div>

      <div class="row py-4">
        <div class="col-lg-6" data-aos="fade-right">
          <div class="img-border1"></div>
          <div class="img-hover-zoom">
            <img src="./assets/img/service-3.jpg" class="img-fluid" alt="" />
          </div>
        </div>
        <div class="col-lg-6 pt-4 content" data-aos="fade-left">
          <h3>
            <i class="fas fa-tools secondary-color"></i> Enterprise Solutions
          </h3>
          <p class="fst-italic">
            Integrating various aspects of a company’s business through the
            interchange of data and information from different business process
            areas and related databases. These solutions helps us to retrieve
            and disseminate important and critical information throughout the
            organization, providing the managers with real-time operating
            information and data.
          </p>
          <p>
            Enterprise solutions make it easier to build and deploy e-business
            applications and solutions. We have implemented for specific kinds
            of business organizations from small to mid-sized industries, which
            need a closed-loop enterprise solution to help plan and manage their
            resources and operations.Mazelonis a reputed enterprise solutions
            company that answers today’s issues, forestalls tomorrow’s needs and
            shapesnext-generation solutions.
          </p>
        </div>
      </div>

      <div class="row py-4">
        <div class="col-lg-6 pt-4 content" data-aos="fade-left">
          <h3>
            <i class="fas fa-tools secondary-color"></i> Technology Consulting
          </h3>
          <p class="fst-italic">
            We progress long-term technology strategy that will enable every
            organization to be agile, flexible, customer responsive. We also
            design, progress, implement solutions, which enable clients to cut
            costs and boost productivity.Goals include Select the exact tools,
            technology required to integrate the multitude of data systems to
            work together, enabling shared data and business process.
          </p>
          <p>
            To develop enterprise architecture, which will have a process layer
            splitting the process flow from the application logic enabling to
            react to forthcoming process change We design the interfaces, do
            project management, and audit the value to guarantee timely and
            smooth integration of these outsourced projects.
          </p>
        </div>
        <div class="col-lg-6" data-aos="fade-right">
          <div class="img-border2"></div>
          <div class="img-hover-zoom">
            <img src="./assets/img/service-4.jpg" class="img-fluid" alt="" />
          </div>
        </div>
      </div>

      <div class="row py-4">
        <div class="col-lg-6" data-aos="fade-right">
          <div class="img-border1"></div>
          <div class="img-hover-zoom">
            <img src="./assets/img/service-5.jpg" class="img-fluid" alt="" />
          </div>
        </div>
        <div class="col-lg-6 pt-4 content" data-aos="fade-left">
          <h3>
            <i class="fas fa-tools secondary-color"></i> Business Consulting
          </h3>
          <p class="fst-italic">
            We pool world-class industry and business process insights, our
            combined knowledge with leading technology expertise to revamp our
            client business process. We understand the vision, mission, collate
            the market requirements, assess the customer observations, conduct
            business process audits, and weigh the internal strengths.
          </p>
          <p>
            Our services aid our customer to set new directions and provides
            differentiated capabilities to capitalize.Through Mazelonbusiness
            Consultancy, we do rearrange and progress the industry of our
            clients, supporting them to be more gainful and maintainable
            growing.
          </p>
        </div>
      </div>
    </div>
  </section>
  <!-- End Services Section -->
</main>
