<div class="head-banner">
  <img src="./assets/img/page-ban-ai.png" />
</div>
<main id="main" class="py-4">
  <!-- ======= Featured Section ======= -->
  <section id="featured" class="featured">
    <div class="container">
      <div class="section-title pb-2">
        <h2>Driving and Scaling Enterprise AI for Business Value</h2>
      </div>
      <p>
        Enterprise needs composable AI framework to manage different AI services
        like ensemble and federated learning with MLOps and DataOps along with
        governance, privacy and lineage.
      </p>
      <div class="row">
        <div class="col-lg-4">
          <div class="icon-box" data-aos="fade-up">
            <img src="./assets/img/ai-icon-1.png" />
            <h3><a>Defining Business Outcome and Metrics</a></h3>
            <p>
              To drive Enterprise AI at a Scale, we must identify the use case
              to demonstrate the value and define potential financial and
              business feasibility of AI deployments.
            </p>
          </div>
        </div>
        <div class="col-lg-4 mt-4 mt-lg-0">
          <div class="icon-box" data-aos="fade-up">
            <img src="./assets/img/ai-icon-2.png" />
            <h3><a>ModelOps and Explainability AI</a></h3>
            <p>
              Operationalizing artificial intelligence(AI) and decision model
              needs AI governance, model monitoring and explainability AI for
              decision driven analytics.
            </p>
          </div>
        </div>
        <div class="col-lg-4 mt-4 mt-lg-0">
          <div class="icon-box" data-aos="fade-up">
            <img src="./assets/img/ai-icon-3.png" />
            <h3><a>Enabling Data-Centric AI Approach</a></h3>
            <p>
              Systematic data-centric approach with data augmentation helps to
              break spurious correlations and lack of variation problems.
              Enhancing data sets improve the system performance.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Featured Section -->
</main>
