import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  imageObject = [{
    image: './assets/img/clients/rungila.png',
    thumbImage: './assets/img/clients/rungila.png'
  }, {
    image: './assets/img/clients/emd.png',
    thumbImage: './assets/img/clients/emd.png'
  }, {
    image: './assets/img/clients/nutriox.png',
    thumbImage: './assets/img/clients/nutriox.png'
  }, {
    image: './assets/img/clients/weaponry.png',
    thumbImage: './assets/img/clients/weaponry.png'
  }, {
    image: './assets/img/clients/ajay_associates.png',
    thumbImage: './assets/img/clients/ajay_associates.png'
  },
  {
    image: './assets/img/clients/medisiddh.png',
    thumbImage: './assets/img/clients/medisiddh.png'
  }];
}
