<div class="head-banner">
  <img src="./assets/img/page-ban-mobapp.png" />
</div>
<main id="main" class="py-4">
  <!-- ======= Featured Section ======= -->
  <section id="featured" class="featured">
    <div class="container">
      <div class="section-title">
        <h2>iOS / Android Mobile Development</h2>
      </div>
      <p>
        We, Mazelon Technologies Pvt Ltd with more than 7 years of experience in
        this realm, has fortunately offered 1000 projects around the globe that
        offers full-fledged mobile application development that can meet the
        requirement of the business. From understanding the requirement of
        brands to the publishing of the respective apps- we have covered all the
        way!
      </p>
      <div class="section-title mt-4">
        <h2>THE MOBILE APPLICATION CREATED BY US ARE :</h2>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="icon-box" data-aos="fade-up">
            <ul class="mobile-features">
              <li>
                <img src="./assets/img/check.png" /> Customized mobile app
                solutions
              </li>
              <li>
                <img src="./assets/img/check.png" /> Integration of payment
                options
              </li>
              <li>
                <img src="./assets/img/check.png" /> Payment gateway
                integrations
              </li>
              <li>
                <img src="./assets/img/check.png" /> Complete documentation of
                integrating app and plugin
              </li>
              <li>
                <img src="./assets/img/check.png" /> Documentation of plugins &
                app integrations
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 mt-4 mt-lg-0">
          <div class="icon-box" data-aos="fade-up">
            <ul class="mobile-features">
              <li>
                <img src="./assets/img/check.png" /> Free bug fixes for 6 months
                after delivery of the product
              </li>
              <li>
                <img src="./assets/img/check.png" /> App support for maintenance
              </li>
              <li>
                <img src="./assets/img/check.png" /> Development support for the
                app at affordable cost
              </li>
              <li>
                <img src="./assets/img/check.png" /> Scalable apps for future
                updates & development
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Featured Section -->

  <!-- ======= Featured Section ======= -->
  <section id="featured" class="featured">
    <div class="container">
      <div class="section-title mt-4">
        <h2>MAZELON OFFERED FOLLOWING SERVICES</h2>
      </div>
      <div class="row">
        <div class="col-lg-3">
          <div class="icon-box" data-aos="fade-up">
            <img src="./assets/img/ios-logo.png" />
            <h3><a>iOS App Development</a></h3>
            <p>
              We have a specialized team that works very hard in producing a
              customized iOS application for the brands with customized
              peculiarities to keep our customers contented with us. All the iOS
              mobile apps are designed with the capabilities
            </p>
          </div>
        </div>
        <div class="col-lg-3 mt-4 mt-lg-0">
          <div class="icon-box" data-aos="fade-up">
            <img src="./assets/img/android.png" />
            <h3><a>Android App Development</a></h3>
            <p>
              With the use of Android Studio and Javascript, our experts create
              a mobile application that is Native in nature. We have full stack
              Android developers that create native applications for brands with
              customized features and other tabs.
            </p>
          </div>
        </div>
        <div class="col-lg-3 mt-4 mt-lg-0">
          <div class="icon-box" data-aos="fade-up">
            <img src="./assets/img/flutter.png" />
            <h3><a>Cross-Platform Mobile Apps</a></h3>
            <p>
              We are skilled at creating multi-platform apps for both Android
              and Ios using the technology like Cordova PhoneGap, Xamarin and
              React Native.
            </p>
          </div>
        </div>
        <div class="col-lg-3 mt-4 mt-lg-0">
          <div class="icon-box" data-aos="fade-up">
            <img src="./assets/img/responsive.png" />
            <h3><a>Mobile Web Development</a></h3>
            <p>
              We have experts in our team that work very hard in offering smooth
              mobile web development services that are engaging with both web
              and mobile application with a fluid exploration feature.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Featured Section -->
</main>
